import React from "react";
import AR from '../../assets/images/ar2.png';

const info = [
    {
        percent: 'Step 1',
        title: 'Join us on Social Media',
        content: 'Our priority is to build a community behind Hatched Apez. Make sure to join our Social Networks.'
    },
    {
        percent: 'Step 2',
        title: 'Giveaways',
        content: 'Constantly giving away Hatched Apez from the total supply of 200 Apez reserved by the team'
    },
    {
        percent: 'Step 3',
        title: 'Rarity Tools & Rarity Sniper',
        content: 'Earning community members represents a priority for Hatched Apez, so listing is a must-do for future Apez'
    },
    {
        percent: 'Step 4',
        title: 'Sharing is Caring',
        content: '50% of the commission earned from secondary sales will be distributed to the holders once we are 100% sold out. This is an one-time event only'
    }
];

export class Roadmap extends React.Component {
    render() {
        return (
            <div className="bg-gradient-to-t to-habg from-habgf mt-5 text-white py-8 pb-10">
                <h2 className="text-white text-5xl text-center pb-10">
                    <div className="font-bold">
                        Launch Roadmap
                    </div>
                </h2>
                <div className="container mx-auto flex flex-col items-start md:flex-row">
                <div className="mt-10 ml-0 md:ml-12 lg:w-2/3 sticky">
                    <div className="container mx-auto w-full ">
                        <div className="relative wrap overflow-hidden h-full">
                            <div className="border-2-2 border-yellow-555 absolute h-full border roadmap1"></div>
                            <div className="border-2-2 border-yellow-555 absolute h-full border roadmap2"></div>
                            
                            {info.map((value, index) => {
                            return (
                                <div className={(index%2 ? 'mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline':'mb-8 flex justify-between items-center w-full right-timeline')}>
                                    <div className="order-1 w-5/12"></div>
                                    <div className={(index%2 ? 'order-1 w-5/12 px-1 py-4 text-right':'order-1  w-5/12 px-1 py-4')}>
                                    <p className="mb-3 text-base text-yellow-300">{value.percent}</p>
                                    <h4 className={(index%2 ? 'mb-3 font-bold text-lg md:text-2xl':'mb-3 font-bold  text-lg md:text-2xl text-left')}>{value.title}</h4>
                                    <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                                        {value.content}
                                    </p>
                                </div>
                                </div>
                                );
                            })}
                        </div>
                    
                        <img className="mt-10 mx-auto" src={AR} />
                    </div>
                </div>
                </div>
            </div>
        );
    }
}