import React from 'react';
import { SpeakerphoneIcon } from '@heroicons/react/outline'

export class Notice extends React.Component {
  render()
  {
    return (
        <div className="bg-indigo-600">
            <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between flex-wrap">
                    <div className="w-0 flex-1 flex items-center">
                        <span className="flex p-2 rounded-lg bg-indigo-800">
                            <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                        <p className="ml-3 font-medium text-white truncate">
                            <span className="md:hidden">Follow us on Social Media for info.</span>
                            <span className="hidden md:inline">Follow us on Social Media for updates about whitelisting.</span>
                        </p>
                    </div>
                </div>
            </div>
      </div>
    );
  }
}