import React from "react";
import T1 from '../../assets/images/t1.jpg';
import T2 from '../../assets/images/t2.jpg';
import T3 from '../../assets/images/t3.jpg';

export class Team extends React.Component {
    render() {
        return (
            <div className="w-full  bg-gradient-to-t from-hafooter to-habg">
                <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 pb-10">
                    <h2 className="text-white text-5xl text-center pb-10">
                        <div className="font-bold">
                            Team
                        </div>
                    </h2>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        <div className="w-full bg-gray-900 rounded-lg shadow p-12 flex flex-col justify-center items-center">
                            <div className="mb-8">
                                <img className="object-center object-cover rounded-full h-50 w-50" src={T1} alt="Ape Julian" />
                            </div>
                            <div className="text-center">
                                <p className="text-xl text-white font-bold mb-2">Ape Julian</p>
                                <p className="text-base text-gray-400 font-normal">Project Manager</p>
                            </div>
                        </div>

                        <div className="w-full bg-gray-900 rounded-lg shadow p-12 flex flex-col justify-center items-center">
                            <div className="mb-8">
                                <img className="object-center object-cover rounded-full h-50 w-50" src={T2} alt="Miss Ape Ade" />
                            </div>
                            <div className="text-center">
                                <p className="text-xl text-white font-bold mb-2">Miss Ape Ade</p>
                                <p className="text-base text-gray-400 font-normal">Illustrator</p>
                            </div>
                        </div>

                        <div className="w-full bg-gray-900 rounded-lg shadow p-12 flex flex-col justify-center items-center">
                            <div className="mb-8">
                                <img className="object-center object-cover rounded-full h-50 w-50" src={T3} alt="Ape Jack" />
                            </div>
                            <div className="text-center">
                                <p className="text-xl text-white font-bold mb-2">Ape Jack</p>
                                <p className="text-base text-gray-400 font-normal">Tech Development</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}