import React from 'react';

const info = [
    {
        question: 'What is the total supply of Hatched Apez?',
        answer: 'A total of 5.000 unique Apez NFTs will be created'
    },
    {
      question: 'How much does it cost to mint an NFT?',
      answer: '0.04 ETH + gas fees',
    },
    {
      question: 'When is the launch?',
      answer: 'Follow us on our social media networks to find out details about launching',
    },
    {
        question: 'How many traits are available?',
        answer: 'Each Hatched Ape was generated by mixing a variety of properties with different chances in the following categories: Background, Earring Accessories, Head Accessories, Eyes, Mouth, Shirt, Fur Variation'
    },
    {
      question: 'Where can I see my NFTs?',
      answer: 'Once minted, the NFTs will show up on Opensea and of course in your wallet address as ERC-721 tokens'
    },
    {
        question: 'Are there second market royalties?',
        answer: 'Yes, 10%. This percentage can decrease over time'
    }
]

export class Faq extends React.Component {
  render(){
    return (
        <div className="bg-gray-100 pb-10 pt-10 px-3 bg-gradient-to-t from-habg to-habgf">
          
      <h2 className="text-white text-5xl text-center pb-10">
        <div className="font-bold">
          FAQ
        </div>
      </h2>
  
      <div className="my-10 max-w-2xl mx-auto space-y-4 lg:space-y-6">
        <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {info.map((value, index) => (
                <div key={value.question} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
                      {index+1}
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-white">{value.question}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-400">{value.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    );
  }
}