import React from "react";

import { BrowserRouter as Router, Route, useLocation, Switch } from 'react-router-dom';

import { Layout } from "./components/layout";
import { Home } from "./components/home";
import { Mint } from "./components/mint";

function App() {
  function NoMatch() {
    let location = useLocation();
  
    return (
      <div className="h-screen w-screen bg-habg flex items-center">
        <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
            <div className="max-w-md">
                <div className="text-5xl text-white font-bold">404</div>
                  <p className="text-2xl md:text-3xl text-white leading-normal">
                    Sorry we couldn't find this page.
                  </p>

                  <a className="mt-5 transform hover:scale-105 transition duration-300 inline-block text-center bg-gradient-to-r from-discord-start to-discord-end border border-transparent rounded-md py-3 px-8 font-medium text-white" href="/">Homepage</a>
                </div>
            <div className="max-w-lg">
          </div>
          
        </div>
      </div>
    );
  }

  return (
    <Router>

      <Layout>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/mint' component={Mint} />

          <Route path="*">
            <NoMatch />
          </Route>

        </Switch>
      </Layout>
      
    </Router>

  );
}

export default App;
