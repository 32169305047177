import React from "react";

import HA1 from '../../assets/images/ha1.jpg';
import HA2 from '../../assets/images/ha2.jpg';
import HA3 from '../../assets/images/ha3.jpg';
import HA4 from '../../assets/images/ha4.jpg';
import HA5 from '../../assets/images/ha5.jpg';
import HA6 from '../../assets/images/ha6.jpg';
import HA7 from '../../assets/images/ha7.jpg';

export class HeroSection extends React.Component {
    render() {
        return (
            <div className="overflow-hidden md:container md:mx-auto">
                <div className="sm:pb-80 lg:mt-10 p-10 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
                    <div className="sm:max-w-lg">
                        <h1 className="text-4xl font font-extrabold tracking-tight text-white sm:text-6xl">
                        Hatched Apez
                        </h1>
                        <p className="mt-4 text-xl text-gray-500">
                        A collection of 5.000 programmatically generated NFT Apez that live on the Ethereum
                        </p>
                    </div>
                    <div>
                        <div className="mt-10">
                        <div aria-hidden="true" className=" hidden md:block mt-20 pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full">
                            <div className=" absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8">
                                <div className="flex items-center space-x-6 lg:space-x-8">
                                <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                    <div className="w-44 h-54 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                                    <img
                                        src={HA1}
                                        alt=""
                                        className="w-full h-full object-center object-cover"
                                    />
                                    </div>
                                    <div className="w-44 h-54 rounded-lg overflow-hidden">
                                    <img
                                        src={HA2}
                                        alt=""
                                        className="w-full h-full object-center object-cover"
                                    />
                                    </div>
                                </div>
                                <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                    <div className="w-44 h-54 rounded-lg overflow-hidden">
                                    <img
                                        src={HA3}
                                        alt=""
                                        className="w-full h-full object-center object-cover"
                                    />
                                    </div>
                                    <div className="w-44 h-54 rounded-lg overflow-hidden">
                                    <img
                                        src={HA4}
                                        alt=""
                                        className="w-full h-full object-center object-cover"
                                    />
                                    </div>
                                    <div className="w-44 h-54 rounded-lg overflow-hidden">
                                    <img
                                        src={HA6}
                                        alt=""
                                        className="w-full h-full object-center object-cover"
                                    />
                                    </div>
                                </div>
                                <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                                    <div className="w-44 h-54 rounded-lg overflow-hidden">
                                    <img
                                        src={HA5}
                                        alt=""
                                        className="w-full h-full object-center object-cover"
                                    />
                                    </div>
                                    <div className="w-44 h-54 rounded-lg overflow-hidden">
                                    <img
                                        src={HA7}
                                        alt=""
                                        className="w-full h-full object-center object-cover"
                                    />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>

                        <div className="mt-8 flex gap-4">
                            <a
                            href="https://twitter.com/HatchedApez"
                            target="_blank"
                            rel="nofollow"
                            className="transform hover:scale-105 transition duration-300 inline-block text-center bg-gradient-to-r from-twitter-start to-twitter-end border border-transparent rounded-md py-3 px-8 font-medium text-white"
                            >
                            <span className="md:hidden">Twitter</span>
                            <span className="hidden md:inline">Follow on Twitter</span>
                            </a>

                            <a
                            href="https://discord.gg/bM8KcrZhFy"
                            target="_blank"
                            rel="nofollow"
                            className="transform hover:scale-105 transition duration-300 inline-block text-center bg-gradient-to-r from-discord-start to-discord-end border border-transparent rounded-md py-3 px-8 font-medium text-white"
                            >
                            <span className="md:hidden">Discord</span>
                            <span className="hidden md:inline">Join Discord</span>
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}