import React, { useEffect } from "react";

import { Faq } from "./sub/faq";
import { Notice } from "./sub/notice";
import { HeroSection } from "./sub/herosection";
import { Roadmap } from "./sub/roadmap";
import { Footer } from "./sub/footer";
import { Team } from "./sub/team";
import { Discord } from "./sub/discord";

export function Home() 
{
  useEffect(async () => {

  }, []);

  return (
    <div className="sm:relative sm:overflow-hidden">
      <Notice></Notice>

      <HeroSection></HeroSection>

      <Roadmap></Roadmap>

      <Discord></Discord>
      
      <Faq></Faq>
    
      <Team></Team>

      <Footer></Footer>
    </div>
    );
}

export default Home;