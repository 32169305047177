import React from "react";

export class Discord extends React.Component {
    render() {
        return (
        <div>
            <div className="bg-bgdiscord">
                <svg className="separator" xmlns="http://www.w3.org/2000/svg" width="100%" height="100" viewBox="0.5 0.2 176 30" preserveAspectRatio="none">
                    <g transform="translate(-13.668562,-111.38266)">
                        <path fill="#49597e" d="M 13.898015,111.51495 H 190.83044 v 26.19241 l -45.97036,-14.43255 -42.22858,7.48354 -45.970361,-14.96709 -31.003265,16.03617 z"/>
                    </g>
                </svg>

                <div className="bg-discord bg-auto">
                    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
                        <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
                        <span className="block">Ready to join our community?</span>
                        <span className="block text-habg">We are on Discord</span>
                        </h2>
                        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="inline-flex rounded-md shadow">
                            <a
                            href="https://discord.gg/bM8KcrZhFy"
                            target="_blank"
                            rel="nofollow"
                            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50"
                            >
                            Join us on Discord
                            </a>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-habgf">
                <svg className="separator" xmlns="http://www.w3.org/2000/svg" width="100%" height="100" viewBox="0.4 0.2 200 44" preserveAspectRatio="none">
                    <g className="separator" transform="translate(-9.2218046,-83.494585)">
                        <path fill="#222533" d="M 9.484815,89.716055 H 209.81018 V 126.90507 L 110.46368,93.705147 9.579391,127.39334 Z" />
                        <path fill="#adbcff" d="M 9.3544335,83.626877 H 209.68181 V 120.29057 L 110.46368,93.705147 9.4490103,120.77195 Z" />
                    </g>
                </svg>
            </div>
        </div>
        );
    }
}